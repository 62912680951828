exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anuncios-js": () => import("./../../../src/pages/anuncios.js" /* webpackChunkName: "component---src-pages-anuncios-js" */),
  "component---src-pages-comunidad-js": () => import("./../../../src/pages/comunidad.js" /* webpackChunkName: "component---src-pages-comunidad-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-equipo-js": () => import("./../../../src/pages/equipo.js" /* webpackChunkName: "component---src-pages-equipo-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luminares-js": () => import("./../../../src/pages/luminares.js" /* webpackChunkName: "component---src-pages-luminares-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-predicas-js": () => import("./../../../src/pages/predicas.js" /* webpackChunkName: "component---src-pages-predicas-js" */),
  "component---src-pages-resolviendo-nuestros-conflictos-js": () => import("./../../../src/pages/resolviendo-nuestros-conflictos.js" /* webpackChunkName: "component---src-pages-resolviendo-nuestros-conflictos-js" */)
}

